import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { cloneDeep, findIndex, map, set } from 'lodash';

import { CleaningTaskAssignmentDto } from '@pimm/services/lib/sms-workforce';
import { GetCleaningPlanParams, ScheduleTypeCategoriesReturn } from './get-cleaning-plan.hook';

type UpdateCleaningTaskReturn = (query: GetCleaningPlanParams, _data: CleaningTaskAssignmentDto) => void;

export const useUpdateCleaningTask = (): UpdateCleaningTaskReturn => {
  const queryClient = useQueryClient();

  const updateTask = useCallback((query: GetCleaningPlanParams, _data: CleaningTaskAssignmentDto) => {
    queryClient.setQueryData(
      ['GetCleaningPlan', query],
      (prevData: ScheduleTypeCategoriesReturn[] | undefined): ScheduleTypeCategoriesReturn[] => {
        if (!prevData) return [];

        return map(prevData, _tasks => {
          if (_tasks.scheduleType == _data.scheduleType) {
            const tasks = cloneDeep(_tasks);
            const countCategories = tasks.categories?.length ?? 0;

            for (let x = 0; x < countCategories; x++) {
              const countSchedules = tasks.categories![x]?.schedules?.length ?? 0;

              for (let y = 0; y < countSchedules; y++) {
                const _cleaningTasks = tasks.categories![x].schedules![y].cleaningTasks;
                const index = findIndex(_cleaningTasks, _ => _.cleaningTaskId === _data.cleaningTaskId);

                if (index !== -1) {
                  // Replace cleaning assignment
                  set(tasks, `categories.${x}.schedules.${y}.cleaningTasks.${index}.cleaningTaskAssignment`, _data);
                  return tasks;
                }
              }
            }
          }

          return _tasks;
        });
      },
    );
  }, []);

  return updateTask;
};
