import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { map, reduce, sortBy, startCase, toLower } from 'lodash';
import moment from 'moment';

import { OpsTaskTrackerDto } from '@pimm/services/lib/sms-workforce';
import { useSiteTime } from '@app/features/store-core';
import { dateDiffToMinutes, stringToDateLocal } from '@app/utils/date-formatter';
import { useGetOpsTasksFlowChartAggLive, useUpdateOpsTasksFlowChart } from '../hooks';
import {
  FlowChartAction,
  FlowChartChecklist,
  FlowChartChecklistInitialState,
  FlowChartPosition,
  FlowChartPositionTask,
  FlowChartReducer,
} from '../reducers/flow-chart-checklist.reducer';
import { OpsPhasePositionAssignee } from '../types';

interface FlowChartChecklistContextReturn {
  flowChart: FlowChartChecklist;
  siteId: string;
  completeTask: (positionId: string, opsTaskTracker: OpsTaskTrackerDto) => void;
  updatePosition: (position: Partial<FlowChartPosition>) => void;
}

type FlowChartChecklistProviderProps = {
  children: React.ReactNode;
  opsPhaseId?: number;
  opsTaskFlowChartAgg: ReturnType<typeof useGetOpsTasksFlowChartAggLive>[0];
  siteId: string;
};

const FlowChartChecklistContext = createContext<FlowChartChecklistContextReturn>(undefined!);

export const FlowChartChecklistProvider = ({ children, opsPhaseId, opsTaskFlowChartAgg, siteId }: FlowChartChecklistProviderProps) => {
  const siteTime = useSiteTime();
  const [flowChart, dispatch] = useReducer(FlowChartReducer, FlowChartChecklistInitialState);

  const updateOpsTasksFlowChart = useUpdateOpsTasksFlowChart();

  const completeTask = useCallback(
    (positionId: string, opsTaskTracker: OpsTaskTrackerDto) => {
      // For reference only
      // dispatch({ type: FlowChartAction.COMPLETE, payload: { ...opsTaskTracker, positionId: positionId } });
      updateOpsTasksFlowChart({ siteId: siteId, opsPhaseId: opsPhaseId }, 'TaskStatusChanged', {
        opsTaskId: opsTaskTracker.opsTaskId,
        opsTaskTrackers: [opsTaskTracker],
        positionId: positionId,
      });
    },
    [opsPhaseId, flowChart, siteId],
  );

  const updatePosition = useCallback(
    (position: Partial<FlowChartPosition>) => {
      // For reference only
      // dispatch({ type: FlowChartAction.UPDATE, payload: position });
      updateOpsTasksFlowChart({ siteId: siteId, opsPhaseId: opsPhaseId }, 'AssignmentChanged', {
        id: position.assignee?.id,
        employeeId: position.assignee?.employeeId,
        positionId: position.positionId,
      });
    },
    [opsPhaseId, flowChart, siteId],
  );

  useEffect(() => {
    let payload: FlowChartChecklist = FlowChartChecklistInitialState;

    if (opsTaskFlowChartAgg.data?.flowChartData) {
      const flowChartData = opsTaskFlowChartAgg.data.flowChartData;

      const dayBlock = siteTime.toDayBlocks()[0];
      const startOfDay = dayBlock.staffStartTime ?? dayBlock.startTime;

      // Transform OpsTaskFlowChartDto into FlowChartPosition
      payload = {
        eligableEmployees: opsTaskFlowChartAgg.data.eligibleEmployees,
        opsPhase: flowChartData.opsPhase,
        salesVolumeProfileId: flowChartData.salesVolumeProfileId,
        positions: map(sortBy(flowChartData.positions, 'title'), _ => {
          const startTime = stringToDateLocal(_.startTime);
          let endTime = stringToDateLocal(_.endTime);

          const assignee: OpsPhasePositionAssignee | undefined = _.opsTaskAssignmentId
            ? {
                id: _.opsTaskAssignmentId,
                name: startCase(toLower([_.firstName, _.lastName].filter(Boolean).join(' '))),
                employeeId: _.employeeId ?? '',
              }
            : undefined;

          const position: FlowChartPosition = {
            positionId: _.positionId as string,
            title: _.title as string,
            color: _.color,
            iconUrl: _.iconUrl,
            assignee: assignee,
            opsTaskAssignmentId: _.opsTaskAssignmentId,
            startTime: startTime,
            endTime: endTime,
            tasks: reduce(
              sortBy(_.opsTasks, 'seq'),
              (tasks: FlowChartPositionTask[], task) => {
                let startTime = startOfDay.toTimeValue(task.taskStartTime);
                let endTime = startOfDay.toTimeValue(task.taskEndTime);

                // Making sure that we render a valid time
                if (startTime && endTime) {
                  if (startTime < startOfDay) startTime = moment(startTime).add(1, 'day').toDate();
                  if (endTime < startTime) endTime = moment(endTime).add(1, 'day').toDate();
                  const durationInMinutes = dateDiffToMinutes(startTime, endTime);
                  const opsTask: FlowChartPositionTask = {
                    opsTaskId: task.opsTaskId as string,
                    opsTaskTrackerId: task.opsTaskTrackerId,
                    seq: task.seq ?? 99,
                    isComplete: task.isComplete,
                    startTime: startTime,
                    endTime: endTime,
                    durationInMinutes: durationInMinutes,
                    offsetInMinutes: 0,
                    items: sortBy(task.opsTaskItems, 'seq'),
                    title: task.title as string,
                    translations: task.translations,
                  };
                  return [...tasks, opsTask];
                }

                return tasks;
              },
              [],
            ),
            translations: _.translations,
          };

          return position;
        }),
      };
    }

    dispatch({ type: FlowChartAction.RESET, payload });
  }, [opsTaskFlowChartAgg.data]);

  return (
    <FlowChartChecklistContext.Provider value={{ flowChart, siteId, completeTask, updatePosition }}>
      {children}
    </FlowChartChecklistContext.Provider>
  );
};

export const FlowChartChecklistConsumer = FlowChartChecklistContext.Consumer;

export const useFlowChartChecklist = () => {
  // get the context
  const context = useContext(FlowChartChecklistContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useFlowChartChecklist was used outside of its Provider');
  }
  return context;
};
