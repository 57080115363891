import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import { first, last, range } from 'lodash';
import moment from 'moment';

import { GetCleaningTasks } from '@pimm/services/lib/sms-workforce/services';
import { ScheduleTypeCategoriesDto } from '@pimm/services/lib/sms-workforce/types';

export type GetCleaningPlanParams = {
  date?: Date;
  siteId?: string;
};

export type ScheduleTypeCategoriesReturn = { date?: Date } & ScheduleTypeCategoriesDto;

export const useGetCleaningPlan = (props?: {
  queryKey?: string;
  query?: GetCleaningPlanParams;
}): [UseQueryResult<Awaited<ScheduleTypeCategoriesReturn[]>>, Dispatch<SetStateAction<GetCleaningPlanParams>>] => {
  const [query, setQuery] = useState<GetCleaningPlanParams>(props?.query ?? {});
  const tasks = useQuery({
    cacheTime: 0,
    enabled: !!query.date && !!query.siteId,
    queryKey: [props?.queryKey ?? 'GetCleaningPlan', query] as [QueryKey, GetCleaningPlanParams],
    queryFn: async ({ queryKey: [key, params] }) => {
      const weekDays = range(7).map(i => params.date!.addDays(i));
      const startOfWeek = first(weekDays);
      const result = await GetCleaningTasks({
        siteId: params.siteId,
        weekStartDateTime: moment(startOfWeek).format().slice(0, 10),
        weekEndDateTime: moment(last(weekDays)).format().slice(0, 10),
      });

      const scheduleTypeCategories = result.scheduleTypeCategories?.reduce((_scheduleTypes: ScheduleTypeCategoriesReturn[], _) => {
        // Distinct daily tasks assignment
        if (_.scheduleType === 'Daily') {
          const dailySchedules = weekDays.map(date => {
            const day = moment(date).locale('en-US').format('dddd');
            const categories = _.categories?.map(category => {
              const schedules = category.schedules?.filter(schedule => schedule.daysOfWeek?.includes(day)) ?? [];

              return {
                ...category,
                schedules: schedules,
              };
            });

            const dailyTask: { date: Date } & ScheduleTypeCategoriesDto = {
              date,
              categories: categories,
              scheduleType: 'Daily',
            };
            return dailyTask;
          });
          return _scheduleTypes.concat(dailySchedules);
        }

        return _scheduleTypes.concat({ ..._, date: startOfWeek });
      }, []);

      return scheduleTypeCategories ?? [];
    },
  });

  return [tasks, setQuery];
};
