import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ArrowBackIcon, ArrowForwardIcon, Box, Divider, HStack, IconButton, useMediaQuery } from 'native-base';
import { cloneDeep, find, forEach, map } from 'lodash';
import FeatherIcons from 'react-native-vector-icons/Feather';
import moment from 'moment';

import { Button, LightBulbIcon } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { CleaningScheduleTypeEnum, CleaningTaskAssignmentDto, ScheduleTypeCategoriesDto } from '@pimm/services/lib/sms-workforce';
import { formatTo, stringToDateLocal } from '@app/utils/date-formatter';
import { MainScreenProps } from '@app/navigations/root';
import { MainContainer } from '@app/components/core';
import { ScreenLayout } from '@app/components/layout';
import { DateTimePopover, useSiteConfig, useSiteTime } from '@app/features/store-core';
import { ModuleTitle } from '@app/features/app';
import { ModalAppDocuments } from '@app/features/store-info/components';
import {
  CleaningAssignmentsProvider,
  CleaningForm,
  GetCleaningPlanParams,
  useGetCleaningEmployees,
  useGetCleaningPlan,
  useUpdateCleaningTask,
} from '@app/features/cleaning-tasks';
import { ResourceLoader } from '@app/components/shared';
import { useGetClientId } from '@app/features/app/hooks';
import { SignalRContext, SignalRData, SignalREffectKey } from '@app/features/signalr/context';

export type CleaningTasksScreenProps = MainScreenProps<'CleaningTasks'>;

export const CleaningTasksScreen: React.FC<CleaningTasksScreenProps> = ({ route: { params, ...route } }) => {
  // const startOfWeek = params.startOfWeek ? moment(params.startOfWeek) : undefined;
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const { locale } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const queryClient = useQueryClient();
  const xClientId = useGetClientId();
  const [cleaningPlan, setCleaningPlanParams] = useGetCleaningPlan();
  const [employees, setEmployeesParams] = useGetCleaningEmployees();
  const [week, setWeek] = useState<ReturnType<typeof siteTime.toStartEndOfWeek> | undefined>(
    // startOfWeek?.isValid() ? toStartEndOfWeek(startOfWeek.toDate()) : undefined,
    undefined,
  );

  const updateCleaningTask = useUpdateCleaningTask();

  const maxDate = moment(siteTime.toStartEndOfWeek().endDate).add(7, 'days').toDate();

  const handleSelectWeek = date => {
    setWeek(siteTime.toStartEndOfWeek(date));
  };

  const handleChangeWeek = (days?: number) => () => {
    if (week) {
      let startOfWeek = siteTime.toStartEndOfWeek().startDate;
      if (days) {
        startOfWeek = moment(week.startDate).add(days, 'days').toDate();
      }
      setWeek(siteTime.toStartEndOfWeek(startOfWeek));
    }
  };

  useEffect(() => {
    if (week?.startDate && siteConfig?.id) {
      const startDate = moment(week.startDate).startOf('day').toDate();
      const endDate = moment(week.endDate).endOf('day').toDate();
      setCleaningPlanParams({ date: week.startDate, siteId: siteConfig.id });
      setEmployeesParams({ showAll: true, siteId: siteConfig.id, startDate, endDate });
    }
  }, [week, siteConfig?.id]);

  useEffect(() => {
    if (siteConfig?.id && !week?.startDate) {
      const startEndOfBlock = siteTime.toStartEndOfBlock();
      const startEndOfWeek = siteTime.toStartEndOfWeek(startEndOfBlock.startTime);
      setWeek(startEndOfWeek);
    }
  }, [siteConfig?.id, week]);

  SignalRContext.useSignalREffect(
    SignalREffectKey,
    jsonString => {
      const message: SignalRData<CleaningTaskAssignmentDto, undefined> = JSON.parse(jsonString);
      if (message.moduleName === 'CleaningTask') {
        if (!xClientId || message.data.clientId === xClientId) return;
        let _data = message.data.data;

        const queryCache = queryClient.getQueryCache();
        const cachedGetCleaningPlan = queryCache.getAll().find(cache => {
          const params = cache.queryKey[1] as GetCleaningPlanParams;
          if (cache.queryKey.includes('GetCleaningPlan') && params.siteId === message.siteId) {
            const selectedDate = _data?.dueDate ? stringToDateLocal(_data.dueDate) : null;
            if (!selectedDate || !week?.startDate || !week?.endDate) return false;

            const isWithinWeek =
              (_data?.scheduleType === CleaningScheduleTypeEnum.Daily || _data?.scheduleType === CleaningScheduleTypeEnum.Weekly) &&
              selectedDate >= week.startDate &&
              selectedDate <= week.endDate;

            const isWithinMonth =
              _data?.scheduleType === CleaningScheduleTypeEnum.Monthly &&
              moment(selectedDate).format('MMM YYYY') === moment(week.startDate).format('MMM YYYY');

            return isWithinWeek || isWithinMonth;
          }
          return false;
        });

        if (cachedGetCleaningPlan?.queryKey[1]) {
          let _taskAssignment = _data;

          // Making sure to include employee name
          if (_data.employeeId && !_data.firstName) {
            const employee = find(employees?.data?.data, ['employee.id', _data.employeeId])?.employee;

            if (employee) {
              _taskAssignment = { ..._data, firstName: employee?.firstName, lastName: employee?.lastName };
            }
          }
          updateCleaningTask(cachedGetCleaningPlan.queryKey[1] as GetCleaningPlanParams, _taskAssignment);
        }
      }
    },
    [],
  );

  return (
    <MainContainer>
      <ScreenLayout
        title={
          <HStack flex={1} alignItems="center">
            <ModuleTitle name={route.name} defaultText="Cleaning Tasks" />
            <ModalAppDocuments
              identifierName="Module.CleaningTasks"
              moduleType="CleaningTasks"
              trigger={triggerProps => {
                if (!triggerProps.documents?.length) return undefined;
                return (
                  <IconButton
                    rounded="lg"
                    borderWidth={1}
                    borderColor="gray.300"
                    ml={2}
                    p={0}
                    w={9}
                    h={9}
                    icon={<LightBulbIcon size="md" />}
                    onPress={triggerProps.onPress}
                  />
                );
              }}
            />
          </HStack>
        }
        actionTool={
          <HStack alignItems="center" justifyContent="flex-end" space={4}>
            <HStack space={1} alignItems="center" justifyContent="space-between">
              <Button variant="unstyled" w={10} onPress={handleChangeWeek(-7)}>
                <ArrowBackIcon size={4} color="gray.700" />
              </Button>
              <DateTimePopover
                key={`date-picker-${week?.startDate.getTime()}`}
                selectedDate={week?.startDate}
                mode="week"
                onChange={handleSelectWeek}
                max={moment(siteTime.toStartEndOfWeek().endDate).add(1, 'week').toDate()} // NOTE: +1, User should be able to load the next week in future from current week
              >
                <Button key={locale} variant="unstyled" startIcon={<FeatherIcons name="calendar" size={18} color="gray.700" />}>
                  {week ? `Wk ${week.weekNumber} (${formatTo(week.startDate, 'MMM DD')} - ${formatTo(week.endDate, 'MMM DD')})` : ''}
                </Button>
              </DateTimePopover>
              <Button disabled={!week?.endDate || maxDate <= week.endDate} variant="unstyled" w={10} onPress={handleChangeWeek(7)}>
                <ArrowForwardIcon size={4} color="gray.700" />
              </Button>

              <Button variant="unstyled" onPress={handleChangeWeek()}>
                Current Week
              </Button>
            </HStack>
          </HStack>
        }
      >
        {!!siteConfig.id && !!week && !!week?.startDate && (
          <CleaningAssignmentsProvider cleaningPlan={cleaningPlan} siteId={siteConfig.id} employees={employees} week={week}>
            <ResourceLoader h="full" isLoading={cleaningPlan.isIdle || cleaningPlan.isLoading}>
              <HStack key={`${week.startDate.toISOString()}`} h="full">
                <Box flex={1}>
                  <CleaningForm />
                </Box>
                {!isSmallScreen && (
                  <>
                    <Divider orientation="vertical" h="full" bg="gray.200" />
                    <Box flex={1}>
                      <CleaningForm scheduleType={CleaningScheduleTypeEnum.Weekly} />
                    </Box>
                  </>
                )}
              </HStack>
            </ResourceLoader>
          </CleaningAssignmentsProvider>
        )}
      </ScreenLayout>
    </MainContainer>
  );
};
