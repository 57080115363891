import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import moment from 'moment';

import { GetOpsTasksFlowChartAgg } from '@pimm/services/lib/sms-workforce/services';
import { useSiteTime } from '@app/features/store-core';

export type GetOpsTasksFlowChartAggLiveParams = {
  opsPhaseId?: number;
  siteId?: string;
};

export const useGetOpsTasksFlowChartAggLive = (props?: {
  queryKey?: string;
  query: GetOpsTasksFlowChartAggLiveParams;
}): [
  UseQueryResult<Awaited<ReturnType<typeof GetOpsTasksFlowChartAgg>>>,
  Dispatch<SetStateAction<GetOpsTasksFlowChartAggLiveParams | undefined>>,
] => {
  const siteTime = useSiteTime();
  const [queryKey] = useState<string>(props?.queryKey ?? 'GetOpsTasksFlowChartAggLive');
  const [query, setQuery] = useState<GetOpsTasksFlowChartAggLiveParams | undefined>(props?.query);

  const flowChart = useQuery({
    cacheTime: 0,
    enabled: !!query?.siteId,
    refetchIntervalInBackground: true,
    refetchOnMount: 'always',
    refetchInterval: () => {
      const timeNow = new Date();
      // Refetch the live positions using the nearest quarter minutes (15, 30, 45 or 0)
      const nearestQuarterHour = timeNow.toNearestQuarterHour();
      return nearestQuarterHour.getTime() - timeNow.getTime();
    },
    queryKey: [queryKey, query] as [QueryKey, GetOpsTasksFlowChartAggLiveParams],
    queryFn: ({ queryKey: [key, params] }) => {
      const startTime = siteTime.toDayBlockNow().startTime;
      return GetOpsTasksFlowChartAgg({
        ...params,
        currentDateTime: moment(startTime).format().slice(0, 19),
      });
    },
  });

  return [flowChart, setQuery];
};
