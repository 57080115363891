import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const PasscodeLockIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 23 18" fill="none" {...props}>
      <Path
        d="M21.3333 7V4.2C21.3333 3.0799 21.3333 2.51984 21.1153 2.09202C20.9235 1.71569 20.6176 1.40973 20.2412 1.21799C19.8134 1 19.2534 1 18.1333 1H4.53325C3.41315 1 2.85309 1 2.42527 1.21799C2.04895 1.40973 1.74299 1.71569 1.55124 2.09202C1.33325 2.51984 1.33325 3.0799 1.33325 4.2V7.8C1.33325 8.9201 1.33325 9.48016 1.55124 9.90798C1.74299 10.2843 2.04895 10.5903 2.42527 10.782C2.85309 11 3.41315 11 4.53325 11H10.3333M11.3333 6H11.3383M16.3333 6H16.3383M6.33325 6H6.33825M18.5833 13V11.25C18.5833 10.2835 17.7998 9.5 16.8333 9.5C15.8668 9.5 15.0833 10.2835 15.0833 11.25V13M11.5833 6C11.5833 6.13807 11.4713 6.25 11.3333 6.25C11.1952 6.25 11.0833 6.13807 11.0833 6C11.0833 5.86193 11.1952 5.75 11.3333 5.75C11.4713 5.75 11.5833 5.86193 11.5833 6ZM16.5833 6C16.5833 6.13807 16.4713 6.25 16.3333 6.25C16.1952 6.25 16.0833 6.13807 16.0833 6C16.0833 5.86193 16.1952 5.75 16.3333 5.75C16.4713 5.75 16.5833 5.86193 16.5833 6ZM6.58325 6C6.58325 6.13807 6.47132 6.25 6.33325 6.25C6.19518 6.25 6.08325 6.13807 6.08325 6C6.08325 5.86193 6.19518 5.75 6.33325 5.75C6.47132 5.75 6.58325 5.86193 6.58325 6ZM14.9333 17H18.7333C19.2933 17 19.5733 17 19.7872 16.891C19.9754 16.7951 20.1284 16.6422 20.2243 16.454C20.3333 16.2401 20.3333 15.9601 20.3333 15.4V14.6C20.3333 14.0399 20.3333 13.7599 20.2243 13.546C20.1284 13.3578 19.9754 13.2049 19.7872 13.109C19.5733 13 19.2933 13 18.7333 13H14.9333C14.3732 13 14.0932 13 13.8793 13.109C13.6911 13.2049 13.5381 13.3578 13.4422 13.546C13.3333 13.7599 13.3333 14.0399 13.3333 14.6V15.4C13.3333 15.9601 13.3333 16.2401 13.4422 16.454C13.5381 16.6422 13.6911 16.7951 13.8793 16.891C14.0932 17 14.3732 17 14.9333 17Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
