import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import moment from 'moment';

import { GetOpsTasksPositioning } from '@pimm/services/lib/sms-workforce/services';
import { GetOpsTasksPositioningQueryParams } from '@pimm/services/lib/sms-workforce';

export type GetOpsTasksPositioningParams = {
  date?: Date;
  siteId?: string;
};

export const useGetOpsTasksPositioning = (
  query?: GetOpsTasksPositioningParams,
  queryKey?: string,
): UseQueryResult<Awaited<ReturnType<typeof GetOpsTasksPositioning>>> => {
  const opsTasksPositioning = useQuery({
    enabled: !!query?.date && !!query?.siteId,
    queryKey: [queryKey ?? 'GetOpsTasksPositioning', query] as [QueryKey, GetOpsTasksPositioningQueryParams],
    queryFn: ({ queryKey: [key, params] }) =>
      GetOpsTasksPositioning({
        ...params,
        date: moment(params!.date).format().slice(0, 19),
      }),
  });

  return opsTasksPositioning;
};
