import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { OpsTaskAssignmentDto, OpsTaskPositioningAggDto, ShiftDto } from '@pimm/services/lib/sms-workforce';
import { GetOpsTasksPositioningParams } from './get-ops-tasks-positioning.hook';

type SignalROpsTaskAssignmentDto = Partial<OpsTaskAssignmentDto> & {
  opsTaskId?: string;
};

type UpdateOpsTasksPositioningReturn = (query: GetOpsTasksPositioningParams, _data: SignalROpsTaskAssignmentDto) => void;

export const useUpdateOpsTasksPositioning = (): UpdateOpsTasksPositioningReturn => {
  const queryClient = useQueryClient();

  const updateOpsTasksPositioning = useCallback((query: GetOpsTasksPositioningParams, _data: SignalROpsTaskAssignmentDto) => {
    queryClient.setQueryData<OpsTaskPositioningAggDto>(
      ['GetOpsTasksPositioning', query],
      (_opsTaskPositioningAggDto): OpsTaskPositioningAggDto => {
        return {
          opsPhases: _opsTaskPositioningAggDto?.opsPhases?.map(_opsPhase => {
            const opsPhase = { ..._opsPhase };
            const isAssigned = !!_data.employeeId;

            if (_opsPhase.opsPhaseId === _data.opsPhaseId) {
              const positions = [...(_opsPhase.positions ?? [])];
              const index = positions.findIndex(_ => _.positionId === _data.positionId);

              if (index !== -1) {
                let shift: ShiftDto | undefined;

                if (_data.employeeId && positions[index].employeeId !== _data.employeeId) {
                  shift = _opsPhase.eligibleEmployees?.find(_employee => _employee.employee?.id === _data.employeeId);
                }
                
                positions[index] = {
                  ...positions[index],
                  opsTaskAssignmentId: isAssigned ? _data.id : undefined,
                  employeeId: isAssigned ? _data.employeeId : undefined,
                  lastName: shift?.employee?.lastName,
                  firstName: shift?.employee?.firstName,
                };
                opsPhase.positions = positions;
              }
            }
            return opsPhase;
          }),
        };
      },
    );
  }, []);

  return updateOpsTasksPositioning;
};
