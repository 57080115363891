import { useEffect, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon, Box, HStack, IconButton, View, useMediaQuery } from 'native-base';
import moment from 'moment';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { range } from 'lodash';
import { useQueryClient } from 'react-query';

import { Button, ButtonGroup, LightBulbIcon } from '@pimm/base';
import { MainScreenProps } from '@app/navigations/root';
import { formatToDateOnly, formatToISOString, stringToDateLocal } from '@app/utils/date-formatter';
import { MainContainer } from '@app/components/core';
import { ModuleTitle } from '@app/features/app';
import { useGetClientId } from '@app/features/app/hooks';
import { SignalRContext, SignalRData, SignalREffectKey } from '@app/features/signalr/context';
import { DateTimePopover, useSiteConfig, useSiteTime } from '@app/features/store-core';
import { ScreenLayout } from '@app/components/layout';
import { GoalSettingsProvider } from '@app/features/ops-plan/context';
import { GetWeeklyGoalsQueryParams, useGetWeeklyFocus } from '@app/features/ops-plan/hooks';
import { useGoalTypes } from '@app/features/store-info';
import { ModalAppDocuments } from '@app/features/store-info/components';
import { WeeklyFocusDto } from '@pimm/services/lib/sms-workforce';
import { DailyPositionalGoals } from './components/daily-positional-goals';
import { WeeklyPositionalGoals } from './components/weekly-positional-goals';

export type OpsPlanScreenProps = MainScreenProps<'OpsPlan'>;

export const OpsPlanScreen: React.FC<OpsPlanScreenProps> = ({ navigation, route: { params, ...route } }) => {
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const goalTypes = useGoalTypes();
  const xClientId = useGetClientId();
  const queryClient = useQueryClient();

  const [tabFocus, setTabFocus] = useState<number>(0);
  const [week, setWeek] = useState<ReturnType<typeof siteTime.toStartEndOfWeekBlock> | undefined>();

  const weeklyFocus = useGetWeeklyFocus({ siteId: siteConfig?.id, weekStartDate: week?.startTime });

  const today = siteTime.today();

  const isEditable = week && week?.endTime > moment(siteTime.toStartEndOfWeekBlock().endTime).add(6, 'day').toDate();
  const isCurrentWeek = week && today > week.startTime && today < week.endTime;

  const handleSelectWeek = date => {
    setWeek(siteTime.toStartEndOfWeekBlock(date));
  };

  const handleChangeWeek = (days?: number) => () => {
    let startOfWeek = siteTime.toStartEndOfWeekBlock()?.startTime;
    if (days) {
      startOfWeek = moment(week?.startTime).add(days, 'days').toDate();
    }
    setWeek(siteTime.toStartEndOfWeekBlock(startOfWeek));
  };

  useEffect(() => {
    if (week?.startTime) {
      // Listen and remember the last state
      navigation.setParams({
        ...params,
        startOfWeek: formatToISOString(week.startTime, true),
      });
    }
  }, [week?.startTime]);

  useEffect(() => {
    if (siteTime.siteId) {
      const today = siteTime.today();
      const startOfWeek = params.startOfWeek ? moment(params.startOfWeek) : undefined;
      let _week = siteTime.toStartEndOfWeekBlock(startOfWeek?.isValid() ? startOfWeek.toDate() : undefined);

      if (today < _week.startTime) {
        _week = siteTime.toStartEndOfWeekBlock();
      }

      setWeek(_week);
    }
  }, [siteTime.siteId]);

  SignalRContext.useSignalREffect(
    SignalREffectKey,
    jsonString => {
      const queryCache = queryClient.getQueryCache();
      const message: SignalRData<WeeklyFocusDto, {}> = JSON.parse(jsonString);

      if (!xClientId || message.data.clientId === xClientId) return;

      if (message.moduleName === 'OpsPlan') {
        const _data = message.data.data;

        if (message.actionName === 'WeeklyFocusChanged') {
          const weekStartDate = stringToDateLocal(_data.weekStartDate);
          const cachedQueryWeeklyFocus = queryCache.getAll().find(cache => {
            const _params = cache.queryKey[1] as GetWeeklyGoalsQueryParams;
            return (
              cache.queryKey.includes('GetWeeklyFocus') &&
              _params.siteId === message.siteId &&
              formatToDateOnly(_params?.weekStartDate) === formatToDateOnly(weekStartDate)
            );
          });

          if (cachedQueryWeeklyFocus && _data) {
            queryClient.setQueryData<WeeklyFocusDto>(cachedQueryWeeklyFocus?.queryKey, (_prevData): WeeklyFocusDto => {
              return {
                ..._data,
                previousWeeklyFocus: _prevData?.previousWeeklyFocus,
              };
            });
          }
        }
      }
    },
    [],
  );

  return (
    <MainContainer>
      <ScreenLayout
        title={
          <HStack alignItems="center">
            <ModuleTitle name={route.name} defaultText="Ops Plan" />
            <ModalAppDocuments
              identifierName="Module.OpsPlan"
              moduleType="Ops Plan"
              trigger={triggerProps => {
                if (!triggerProps.documents?.length) return undefined;
                return (
                  <IconButton
                    rounded="lg"
                    borderWidth={1}
                    borderColor="gray.300"
                    ml={2}
                    p={0}
                    w={9}
                    h={9}
                    icon={<LightBulbIcon size="md" />}
                    onPress={triggerProps.onPress}
                  />
                );
              }}
            />
          </HStack>
        }
        actionTool={
          <HStack space={4}>
            <Box w="1/2" flex={1} alignItems="flex-end">
              {isSmallScreen && (
                <ButtonGroup value={tabFocus} onChange={setTabFocus}>
                  <ButtonGroup.Item alignItems="center" value={0} minW={120}>
                    Talk to Me
                  </ButtonGroup.Item>
                  <ButtonGroup.Item alignItems="center" value={1}>
                    Positional Goals
                  </ButtonGroup.Item>
                </ButtonGroup>
              )}
            </Box>

            <HStack space={2} alignItems="center" justifyContent="flex-end" width="1/2">
              <Button variant="unstyled" w={10} onPress={handleChangeWeek(-7)}>
                <ArrowBackIcon size={4} color="gray.700" />
              </Button>
              <DateTimePopover
                key={`date-picker-${week?.startTime?.getTime()}`}
                selectedDate={week?.startTime}
                mode="week"
                onChange={handleSelectWeek}
                max={moment(siteTime.toStartEndOfWeekBlock().endTime).add(6, 'day').toDate()}
              >
                <Button variant="unstyled" startIcon={<FeatherIcon name="calendar" size={18} color="gray.700" />}>
                  {week
                    ? `Wk ${week.weekNumber} (${range(2)
                        .map(d =>
                          moment(week.startTime)
                            .add(6 * d, 'day')
                            .format('MMM DD'),
                        )
                        .join(' - ')})`
                    : ''}
                </Button>
              </DateTimePopover>
              <Button variant="unstyled" disabled={isEditable} w={10} onPress={handleChangeWeek(7)}>
                <ArrowForwardIcon size={4} color="gray.700" />
              </Button>

              <Button variant="unstyled" isDisabled={isCurrentWeek} onPress={handleChangeWeek()}>
                Current Week
              </Button>
            </HStack>
          </HStack>
        }
      >
        {!!week && !!siteConfig?.id && (
          <GoalSettingsProvider
            brandId={siteConfig.companyInfo?.brand?.id}
            customerId={siteConfig?.companyInfo?.customerId}
            marketId={siteConfig?.config?.marketId}
          >
            <View display="flex" flexDirection={{ md: 'column', xl: 'row' }} h="full" w="full">
              {(!isSmallScreen || tabFocus === 0) && (
                <WeeklyPositionalGoals goalTypes={goalTypes.data} siteId={siteConfig.id} week={week} weeklyFocus={weeklyFocus} />
              )}
              {(!isSmallScreen || tabFocus === 1) && (
                <DailyPositionalGoals
                  week={week}
                  siteId={siteConfig.id}
                  goalTypeIds={weeklyFocus?.data?.goalTypeIds}
                  marketId={siteConfig?.config?.marketId}
                  brandId={siteConfig.companyInfo?.brand?.id}
                />
              )}
            </View>
          </GoalSettingsProvider>
        )}
      </ScreenLayout>
    </MainContainer>
  );
};
