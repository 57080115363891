import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { last } from 'lodash';

import { OpsTaskAssignmentDto, OpsTaskPositioningAggDto } from '@pimm/services/lib/sms-workforce';
import { formatToISOString } from '@app/utils/date-formatter';
import { ResourceLoader } from '@app/components/shared';
import { useGetClientId } from '@app/features/app/hooks';
import { SignalRContext, SignalRData, SignalREffectKey } from '@app/features/signalr/context';
import { useDayBlockFocus, useSiteConfig, useSiteTime } from '@app/features/store-core';
import {
  GetOpsTasksPositioningParams,
  OpsPhasePositioningProvider,
  OpsPhasesPositioning,
  useGetBrandSalesVolumeProfiles,
  useGetOpsTasksPositioning,
  useUpdateOpsTasksPositioning,
} from '@app/features/flow-chart';

type NonServicePlanProps = {
  selectedDate?: Date;
};

export const NonServicePlan = ({ selectedDate }: NonServicePlanProps) => {
  const queryClient = useQueryClient();
  const xClientId = useGetClientId();
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const [salesVolumeProfiles, setSalesVolumeProfilesParams] = useGetBrandSalesVolumeProfiles();

  const startEndOfBlock = siteTime.toStartEndOfBlock(selectedDate);

  const opsTasksPositioning = useGetOpsTasksPositioning({
    date: startEndOfBlock.startTime,
    siteId: siteConfig.id,
  });

  const updateOpsTasksPositioning = useUpdateOpsTasksPositioning();

  const isDisabled = !!startEndOfBlock.endTime && startEndOfBlock.endTime <= siteTime.today();

  SignalRContext.useSignalREffect(
    SignalREffectKey,
    jsonString => {
      const queryCache = queryClient.getQueryCache();
      const message: SignalRData<
        OpsTaskAssignmentDto & { opsTaskAssignment?: { opsPhaseId?: number; siteId?: string }; opsTaskId?: string }
      > = JSON.parse(jsonString);
      const _data = message.data.data;

      if (!xClientId || message.data.clientId === xClientId) return;

      if (message.moduleName === 'OpsPlan') {
        const opsTaskAssignment = _data.opsTaskAssignment;

        if (message.actionName === 'ProfileChanged') {
          queryClient.refetchQueries({ queryKey: 'GetOpsTasksPositioning' });
          return;
        }

        if (message.actionName === 'AssignmentChanged') {
          const cachedGetOpsTaskPositioningAggDto = queryCache.getAll().find(cache => {
            const params = cache.queryKey[1] as GetOpsTasksPositioningParams;

            return (
              cache.queryKey.includes('GetOpsTasksPositioning') &&
              params.siteId === (_data.siteId || opsTaskAssignment?.siteId) &&
              params.date &&
              formatToISOString(params.date, true) === _data.assignDate?.slice(0, 10)?.slice(0, 10)
            );
          });

          if (cachedGetOpsTaskPositioningAggDto?.queryKey[1]) {
            const isDeleteRequest = message.data.origin === 'DeleteOpsTaskAssignmentRequestHandler';

            updateOpsTasksPositioning(cachedGetOpsTaskPositioningAggDto.queryKey[1], {
              ...message.data.data,
              employeeId: isDeleteRequest ? undefined : message.data.data?.employeeId,
            });
          }
        }
      }
    },
    [],
  );

  useEffect(() => {
    if (siteConfig) {
      setSalesVolumeProfilesParams({
        brandId: siteConfig.companyInfo?.brand?.id,
        marketId: siteConfig.config?.marketId,
      });
    }
  }, [siteConfig]);

  return (
    <ResourceLoader h="full" isLoading={!siteConfig.id || opsTasksPositioning.isIdle || opsTasksPositioning.isLoading}>
      {!!siteConfig.id && (
        <OpsPhasePositioningProvider
          opsTasksPositioning={opsTasksPositioning}
          salesVolumeProfiles={salesVolumeProfiles}
          selectedDate={startEndOfBlock.startTime}
          siteId={siteConfig.id}
        >
          <OpsPhasesPositioning isDisabled={isDisabled} selectedDate={startEndOfBlock.startTime} />
        </OpsPhasePositioningProvider>
      )}
    </ResourceLoader>
  );
};
