import { useMutation } from 'react-query';
import { Box, FlatList, useBreakpointValue, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';

import { Button, ZapIcon } from '@pimm/base';
import { formatToISOString } from '@app/utils/date-formatter';
import { SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { useOpsPhasePositioning } from '../context';
import { OpsPhasePositions } from './ops-phase-positions';

type OpsPhasesPositioningProps = {
  isDisabled?: boolean;
  selectedDate?: Date;
};

export const OpsPhasesPositioning = ({ selectedDate, isDisabled }: OpsPhasesPositioningProps) => {
  const { t } = useTranslation();
  const numColumns = useBreakpointValue({ sm: 2, xl: 3 });
  const opsPhasePositioning = useOpsPhasePositioning();

  const autoAssignOpsTaskAssignment = useMutation({ mutationFn: SmsWorkforceApi.AutoAssignOpsTaskAssignment });

  const handleAutoAssign = async () => {
    if (opsPhasePositioning.siteId && selectedDate) {
      await autoAssignOpsTaskAssignment.mutateAsync({
        date: formatToISOString(selectedDate),
        siteId: opsPhasePositioning.siteId,
      });

      opsPhasePositioning.opsTasksPositioning.refetch();
    }
  };

  return (
    <VStack flex={1}>
      {!isDisabled && (
        <Box px={4} minH="60px" borderBottomWidth={1} alignItems="flex-end" justifyContent="center">
          <Button
            accessibilityLabel="Reset and Auto Assign Employee"
            w="140px"
            isLoading={autoAssignOpsTaskAssignment.isLoading || opsPhasePositioning.opsTasksPositioning.isFetching}
            onPress={handleAutoAssign}
            startIcon={<ZapIcon size="18px" color="black" />}
          >
            {t('common:auto_assign')}
          </Button>
        </Box>
      )}

      <FlatList
        p={2}
        h="full"
        w="full"
        data={opsPhasePositioning.positioning.opsPhases}
        extraData={[numColumns]}
        numColumns={numColumns}
        renderItem={({ item: opsPhase }) => (
          <Box p={1} w={`1/${numColumns}`}>
            <Box>
              <OpsPhasePositions opsPhase={opsPhase} isDisabled={isDisabled} />
            </Box>
          </Box>
        )}
      />
    </VStack>
  );
};
