import { useEffect, useState } from 'react';
import { Box, HStack, VStack, View, useMediaQuery } from 'native-base';
import { findIndex, first, isEmpty, last, range } from 'lodash';
import moment from 'moment';
import { useQueryClient } from 'react-query';

import { ButtonGroup, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { DailyPositionGoalDto, GetDailyPositionGoalsQueryParams } from '@pimm/services/lib/sms-workforce';
import { formatTo, formatToISOString, formatToDateOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { DailyGoalsForm } from '@app/features/ops-plan';
import { useGetClientId } from '@app/features/app/hooks';
import { SignalRContext, SignalRData, SignalREffectKey } from '@app/features/signalr/context';
import { DailyPositionGoalsProvider, useGoalSettings } from '@app/features/ops-plan/context';
import { useGetGoalCategories } from '@app/features/ops-plan/hooks';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { ResourceLoader } from '@app/components/shared';

type DailyPositionalGoalsProps = {
  siteId: string;
  brandId?: string;
  marketId?: number;
  goalTypeIds?: number[];
  week: ReturnType<ReturnType<typeof useSiteTime>['toStartEndOfWeekBlock']>;
};

export const DailyPositionalGoals = ({ siteId, brandId, marketId, goalTypeIds, week }: DailyPositionalGoalsProps) => {
  const [isSmallScreen, isLargeScreen] = useMediaQuery([{ maxWidth: 1300 }, { maxWidth: 1600 }]);
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const { weeklyFocusSettings } = useGoalSettings();
  const xClientId = useGetClientId();
  const queryClient = useQueryClient();

  const [dayBlocks, setDayBlocks] = useState<DayBlock[]>([]);
  const [tabDayFocus, setTabDayFocus] = useState<string>();

  const [goalCategories] = useGetGoalCategories({ query: { brandId, marketId } });
  const today = siteTime.today();

  const startEndOfBlock = !isEmpty(dayBlocks)
    ? {
        startTime: first(dayBlocks)!.startTime,
        endTime: last(dayBlocks)!.endTime,
      }
    : undefined;

  const isPastDate = !startEndOfBlock || startEndOfBlock.endTime < today;

  const handleChangeDay = (day: string) => {
    setTabDayFocus(day);
    setDayBlocks(siteTime.toDayBlocks(new Date(day)));
  };

  useEffect(() => {
    if (week.startTime) {
      const today = siteTime.today();
      let dateFocus: Date = week.startTime;

      if (today >= week.startTime && today < week.endTime) {
        dateFocus = siteTime.toStartEndOfBlock().startTime!;
      }

      setTabDayFocus(formatToISOString(dateFocus));
      setDayBlocks(siteTime.toDayBlocks(dateFocus));
    }
  }, [week.startTime]);

  SignalRContext.useSignalREffect(
    SignalREffectKey,
    jsonString => {
      const queryCache = queryClient.getQueryCache();
      const message: SignalRData<DailyPositionGoalDto, {}> = JSON.parse(jsonString);
      if (!xClientId || message.data.clientId === xClientId || message.moduleName !== 'OpsPlan') return;

      if (message.actionName === 'TaskStatusChanged') {
        const _data = message.data.data;

        const goalDate = stringToDateLocal(_data.goalDate);
        const endOfweek = siteTime.toStartEndOfWeekBlock(week.startTime).endTime;

        if (moment(goalDate).isBetween(week?.startTime, endOfweek, 'day', '[)')) {
          const cachedQueryDailyPositionGoals = queryCache.getAll().find(cache => {
            const _params = cache.queryKey[1] as GetDailyPositionGoalsQueryParams;

            return (
              cache.queryKey.includes('GetDailyPositionGoals') &&
              _params.siteId === siteId &&
              formatToDateOnly(_params?.startDate) === formatToDateOnly(goalDate)
            );
          });

          if (cachedQueryDailyPositionGoals && _data) {
            queryClient.setQueryData<DailyPositionGoalDto[]>(cachedQueryDailyPositionGoals.queryKey, (_prevData = []) => {
              const index = findIndex(_prevData, { id: _data.id });

              if (index !== -1) {
                const newData = [..._prevData];
                newData[index] = { ..._prevData[index], selectedGoalId: _data.selectedGoalId };
                return newData;
              } else {
                queryClient.invalidateQueries({ queryKey: cachedQueryDailyPositionGoals.queryKey });
                queryClient.refetchQueries({ queryKey: cachedQueryDailyPositionGoals.queryKey });

                return _prevData;
              }
            });
          }
        }
      }
    },
    [],
  );

  return (
    <DailyPositionGoalsProvider dayBlocks={dayBlocks} siteId={siteId}>
      <View flex={{ xl: 1 }} h="full" w={{ md: 'full' }}>
        <ResourceLoader h="full" isLoading={goalCategories.isIdle || goalCategories.isLoading}>
          <VStack h="full">
            <HStack space={2} alignItems="center" justifyContent="space-between" py={2} px={5} bg="white" borderBottomWidth={1} minH={57}>
              <Box flex={1}>
                <Text size="xl" fontWeight={700} lineHeight="xs" color="black" textTransform="uppercase">
                  {translate(weeklyFocusSettings.data?.positionGoalsHeader, weeklyFocusSettings.data?.positionGoalsHeaderTranslations)}
                </Text>

                <Text
                  size={!isSmallScreen && isLargeScreen ? 'sm' : 'md'}
                  fontWeight={500}
                  lineHeight="xs"
                  color="gray.700"
                  numberOfLines={2}
                  ellipsizeMode="tail"
                >
                  {translate(weeklyFocusSettings.data?.positionGoalsCaption, weeklyFocusSettings.data?.positionGoalsCaptionTranslations)}
                </Text>
              </Box>

              <HStack space={2} alignItems="center" justifyContent="space-between">
                {!isEmpty(dayBlocks) && (
                  <ButtonGroup value={tabDayFocus} onChange={handleChangeDay}>
                    {range(7).map(day => {
                      const date = moment(week.startTime).add(day, 'days').toDate();
                      const isoDate = formatToISOString(date);
                      return (
                        <ButtonGroup.Item key={isoDate} pr={1.5} value={isoDate} minW={70}>
                          {formatTo(date, 'ddd')}
                        </ButtonGroup.Item>
                      );
                    })}
                  </ButtonGroup>
                )}
              </HStack>
            </HStack>

            <View flex={1}>
              <DailyGoalsForm categories={goalCategories.data} disabled={isPastDate} goalTypeIds={goalTypeIds} />
            </View>
          </VStack>
        </ResourceLoader>
      </View>
    </DailyPositionGoalsProvider>
  );
};
