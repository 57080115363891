import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { isEmpty } from 'lodash';

import { FlowChartAggDto, OpsTaskAssignmentDto, ShiftDto } from '@pimm/services/lib/sms-workforce';
import { GetOpsTasksFlowChartAggLiveParams } from './get-ops-task-flow-chart-agg-live.hook';

type SignalROpsTaskAssignmentDto = OpsTaskAssignmentDto & {
  opsTaskId?: string;
};

type UpdateOpsTasksFlowChartReturn = (
  query: GetOpsTasksFlowChartAggLiveParams,
  actionName: string,
  _data: SignalROpsTaskAssignmentDto,
) => void;

export const useUpdateOpsTasksFlowChart = (): UpdateOpsTasksFlowChartReturn => {
  const queryClient = useQueryClient();

  const updateOpsTasksFlowChart = useCallback(
    (query: GetOpsTasksFlowChartAggLiveParams, actionName: string, _data: SignalROpsTaskAssignmentDto) => {
      queryClient.setQueryData<FlowChartAggDto>(['GetOpsTasksFlowChartAggLive', query], (_flowChartAgg): FlowChartAggDto => {
        const opsTaskId = _data.opsTaskId;
        const isAssigned = !!_data.employeeId;
        const positions = [...(_flowChartAgg?.flowChartData?.positions ?? [])];
        const index = positions?.findIndex(_ => _.positionId === _data.positionId || _.opsTasks?.some(t => t.opsTaskId === opsTaskId));

        if (!isEmpty(positions) && index >= 0) {
          if (actionName === 'AssignmentChanged') {
            let shift: ShiftDto | undefined;

            if (_data.employeeId && positions[index].employeeId !== _data.employeeId) {
              shift = _flowChartAgg?.eligibleEmployees?.find(_ => _.employee?.id === _data.employeeId);
            }

            // position assignment
            positions[index] = {
              ...positions[index],
              opsTaskAssignmentId: isAssigned ? _data.id : undefined,
              employeeId: isAssigned ? _data.employeeId : undefined,
              lastName: shift?.employee?.lastName,
              firstName: shift?.employee?.firstName,
              opsTasks: positions[index].opsTasks?.map(_opsTask => {
                return {
                  ..._opsTask,
                  isComplete: isAssigned ? _opsTask.isComplete : false,
                  opsTaskTrackerId: isAssigned ? _opsTask.opsTaskTrackerId : undefined,
                };
              }),
            };
          } else if (actionName === 'TaskStatusChanged') {
            const _opsTaskTrackers = _data.opsTaskTrackers ?? [];

            // completion of position task
            positions[index] = {
              ...positions[index],
              opsTasks: positions[index].opsTasks?.map(_opsTask => {
                const _tracker = _opsTaskTrackers.find(_ => _.opsTaskId === _opsTask.opsTaskId);

                if (_tracker || (!isAssigned && _opsTask.opsTaskId === opsTaskId)) {
                  return {
                    ..._opsTask,
                    isComplete: !!_tracker?.id,
                    opsTaskTrackerId: _tracker?.id,
                  };
                }
                return _opsTask;
              }),
            };
          }
        }

        return {
          ..._flowChartAgg,
          flowChartData: {
            ..._flowChartAgg?.flowChartData,
            positions: positions,
          },
        };
      });
    },
    [],
  );

  return updateOpsTasksFlowChart;
};
