import { useState } from 'react';
import { useMutation } from 'react-query';
import { Box, HStack, VStack } from 'native-base';

import { Button, ControlInput, FormHelperText, Text } from '@pimm/base';
import { ChangePinRequest, PinResetDto } from '@pimm/services/lib/sms-tenants';
import { ChangePin } from '@pimm/services/lib/sms-tenants/services';
import { useFormPinSetup } from '../hooks';
import PinInput from './pin-input';

type FormPinSetupProps = {
  userId: string;
  onCancel?: () => void;
  onEndChange: (payload?: PinResetDto) => void;
};

export const FormPinSetup = ({ userId, ...props }: FormPinSetupProps) => {
  const [step, setStep] = useState<'enter-pin' | 'confirm-pin' | 'enter-password'>('enter-pin');
  const form = useFormPinSetup();
  const errMessage = (form.formState.errors.pin?.message || form.formState.errors.root?.message) as string;

  const changePin = useMutation({
    mutationFn: ChangePin,
    onSuccess: data => props.onEndChange({ ...data, resultMessage: '0 day old' }),
    onError: (error: any) => {
      // Reset input fields but keep the error message
      form.reset(undefined, { keepErrors: true });
      // Return to the first step
      setStep('enter-pin');
      
      form.setError('root', {
        type: 'custom',
        message:
          error.code === 400
            ? 'You have entered an invalid password. Please try again'
            : 'Sorry, something went wrong there. Please try again',
      });
    },
  });

  const handlePINChange = (_value: string) => {
    if (!_value) return;

    form.clearErrors();

    if (step === 'enter-pin') {
      form.setValue('pin', _value);
      setStep('confirm-pin');
      return;
    }

    if (step === 'confirm-pin') {
      const isValid = form.getValues().pin === _value;
      // Display error message if pin does not match
      if (!isValid) {
        form.setError('pin', { type: 'custom', message: 'Pin does not match. Please try again' });
      }
      setStep(isValid ? 'enter-password' : 'enter-pin');
    }
  };

  const handleSavePin = () => {
    const { currentPassword, pin } = form.getValues();
    const payload: ChangePinRequest = {
      userId: userId,
      currentPassword: btoa(currentPassword),
      newPin: pin,
    };
    changePin.mutate(payload);
  };

  return (
    <VStack height="full" w="full">
      <HStack alignItems="center" justifyContent="center" pt={4} px={4} h="50px">
        <Text size="2xl" fontWeight={700} color="gray.900" textAlign="center">
          PIN Setup
        </Text>
      </HStack>

      <VStack space={2} justifyContent="center" pt={5} pb={4}>
        {step === 'enter-password' ? (
          <Box alignItems="center" justifyContent="center" px={6}>
            <Text size="md" fontWeight={600} color="gray.700" mb={2}>
              Please enter your password to continue
            </Text>
            <HStack space={2} alignItems="center">
              <ControlInput
                control={form.control}
                type="password"
                name="currentPassword"
                _helperText={{ textAlign: 'center' }}
                _input={{ w: 300 }}
              />
            </HStack>
          </Box>
        ) : (
          <Box alignItems="center" justifyContent="center" px={6}>
            <Text size="md" fontWeight={600} color="gray.700" mb={2}>
              {step === 'enter-pin' ? 'Please enter your 4 digit PIN' : 'Please re-enter your 4 digit PIN'}
            </Text>
            <HStack space={2} alignItems="center">
              <PinInput key={step} onChange={handlePINChange} />
            </HStack>
          </Box>
        )}

        <FormHelperText error visible={!!errMessage} textAlign="center">
          {errMessage ?? ''}
        </FormHelperText>
      </VStack>

      <HStack alignItems="center" justifyContent="flex-end" py={3} px={4} borderTopWidth={1}>
        <HStack space={3} justifyContent="flex-end">
          <Button variant="unstyled" minWidth={120} disabled={changePin.isLoading} onPress={props.onCancel}>
            Cancel
          </Button>

          <Button minW={120} disabled={!form.formState.isValid} isLoading={changePin.isLoading} onPress={form.handleSubmit(handleSavePin)}>
            Save
          </Button>
        </HStack>
      </HStack>
    </VStack>
  );
};
